<template>
  <div class="text-center py-3">
    <h4 class="mb-3">
      Il prezzo della richiesta selezionata è <strong>{{entityPrice}}</strong>.
      <br class="mb-2" />
      Il tuo credito disponibile è pari a <strong>{{walletPrice}}</strong>.
    </h4>
    <div v-if="hasFunds">
      <p>
        Clicca Invia richiesta per finalizzare l’ordine.
        <br />
        Saranno sottratti <strong>{{entityPrice}}</strong> dal tuo wallet disponibile.
      </p>
    </div>
    <div v-else>
      <p>
        Il tuo credito disponibile non è sufficiente per finalizzare l’ordine.
        <br />
        Ricarica il tuo wallet al seguente link:
        <br />
        <b-button
          @click="routeTo('/wallet')"
          variant="success"
          class="mt-4"
        >
          RICARICA
        </b-button>
      </p>
    </div>
  </div>
</template>

<script>

import { formatCurrency } from '../../../utils/formatterHelper';

export default {
  name: 'PaymentSection',
  props: {
    entity: {
      entityType: String,
    },
    requestPrice: {
      currency: String,
      amount: Number,
    },
    hasFunds: Boolean,
  },
  computed: {
    entityPrice() {
      // entity.price?
      // console.log('this.entity.price?', this.entity);
      // return `${this.requestPrice.amount} ${this.requestPrice.currency}`;
      return formatCurrency(this.requestPrice.amount, 'it', this.requestPrice.currency);
    },
    walletAmount() {
      return this.$store.getters['wallet/getWallet']?.amount.amount || 0;
    },
    walletCurrency() {
      return this.$store.getters['wallet/getWallet']?.amount.currency || '';
    },
    walletPrice() {
      return formatCurrency(this.walletAmount, 'it', this.walletCurrency);
    },
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>

</style>
